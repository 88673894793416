import React from 'react'
import './page.css'
import './home.css'
import About from './About';
import 'bootstrap/dist/css/bootstrap.min.css';
export const Homepage = () => {
    return (
        <div className='head-bng'>
            <About></About>
        </div>
    )
}
