import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import './service.css'
import { GiHiveMind } from "react-icons/gi";
import { LiaDigitalTachographSolid } from "react-icons/lia";
import { FaCloud } from "react-icons/fa";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr";

export const Service = () => {
  return (
    <div>
      <Navbar />
      <div className='serviceBack'></div>
      <div className='serviceInstruction'>

        {/* <p className='srintro'>We prioritize understanding your unique challenges and goals, ensuring that our solutions are not only technically sound but also aligned with your business objectives.</p> */}

        <h2>Our IT Services</h2>
        <ul className='serviceList'>
          <li className='listItem'>
            <div className='srIcon'><GiHiveMind /></div>
            <h6>Artificial Inteligence</h6>
          </li>
          <li className='listItem'>
            <div className='srIcon'><LiaDigitalTachographSolid /></div>
            <h6>Digital Experience Paltform</h6>
          </li><li className='listItem'>
            <div className='srIcon'><FaCloud /></div>
            <h6>Cloud And DevOps</h6>
          </li><li className='listItem'>
            <div className='srIcon'><MdOutlineIntegrationInstructions /></div>
            <h6>Software Development and Integration</h6>
          </li>
          <li className='listItem'>
            <div className='srIcon'><MdManageHistory /></div>
            <h6>Project Management</h6>
          </li>
          <li className='listItem'>
            <div className='srIcon'><GrVmMaintenance /></div>
            <h6>Data and Analytics</h6>
          </li>
        </ul>
        <h2>Why Choose Us?</h2>
        <ul className='serviceList wcus'>
          <li className='listItem'>
            <h6>Expertise</h6>
          </li>
          <li className='listItem'>
            <h6>Custom Solutions</h6>
          </li>
          <li className='listItem'>
            <h6>Customer-Centric Approach</h6>
          </li>
          <li className='listItem'>
            <h6>Cutting-Edge Technology</h6>
          </li>
          </ul>
      </div>
      <Footer />
    </div>
  )
}
