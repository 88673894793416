import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import FormComponent from '../Components/SubmitForm/SubmitForm'
import './contact.css'


export const Contact = () => {
  return (
    <div> 
      <Navbar/>
      <div className='cntBack'>
        <p>You Asfmkmkfpire, We Transpire</p>
       </div>
       <FormComponent/>
    <Footer/>
    </div>
  )
}
