import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import './client.css'
export const Client = () => {
    return (
        <div>
            <Navbar />
            <div className='clientBack'></div>
            <div className='serviceInstruction'>
                <div className='srintro'>We dedicated to delivering bespoke solutions that redefine industry standards. Our full-stack expertise, commitment to agility, and collaborative approach ensure tailor-made software, seamless integration, and comprehensive services. With a focus on your unique needs, we strive to be your strategic partner in unlocking unparalleled business potential, offering scalability, cost-effectiveness, and innovative solutions for sustained success in the digital era.</div>
            </div>
            {/* <div className='abouClient'>
                <h2>Our Partners</h2>
                <ul className='clientList'>
                    <li className='clientItem'>
                        <div className='clientimg hp'></div> </li>
                    <li className='clientItem'>
                        <div className='clientimg'>
                            <div className='clientimg emps'></div></div>
                    </li>
                </ul>
            </div> */}
            <Footer />
        </div>
    )
}
