import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../Assets/About1.jpeg'
import img2 from '../Assets/About2.jpeg'

import './CarouselPage.css'

function CarouselPage() {
  return (
    <div>
      <Carousel interval={15000}>
        <Carousel.Item>
          <img style={{ height: '60vh' }}
            className="d-block w-100"
            src={img1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h2>Digitally Transformed Human Experiences</h2>
            <p>Critical decisions are comparable to 1Waydoor, as they cannot be 
              reversed once made. Your trustee partner in Software services and 
              Consulting for your business. </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img style={{ height: '60vh' }}
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />

          <Carousel.Caption>
            <h2>Software Services Redefined</h2>
            <p>Our team of expert consultants and developers are dedicated to delivering 
              solutions that align with your unique goals and challenges. With a commitment 
              to excellence and a passion for problem-solving, we empower businesses to thrive 
              in the ever evolving digital landscape. Partner with us to unlock your full 
              potential and achieve your digital ambitions.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselPage