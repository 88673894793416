// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAI7yJN5ONeVTo0vG-vCeMlwf3Vr0nalsQ",
  authDomain: "my-react-blog-8d6e0.firebaseapp.com",
  projectId: "my-react-blog-8d6e0",
  storageBucket: "my-react-blog-8d6e0.appspot.com",
  messagingSenderId: "183679952235",
  appId: "1:183679952235:web:304d8d648e7e998dd0982e"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 export const db = getFirestore(app);
 export const myCollection = collection(db, 'myCollection');

