/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import './Navbar.css'
import logo from '../Assets/Logo_1WD.png'
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';

export const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <div className='Navbar'>
      <div className="nav-logo">
        <NavLink to='/'><img src={logo} alt='logo' className='logo-size' /></NavLink>
        <span style={{ padding: '10px' }}>1WAYDOOR LLC</span>
      </div>
      <ul className={showMediaIcons ? "nav-menu mobile-nav-menu" : "nav-menu"}>
        <li><NavLink to='/about'>About</NavLink></li>
        <li><NavLink to='/service'>Services</NavLink></li>
        {/* <li><NavLink to='/client'>Clients</NavLink></li>
        <li><NavLink to='/contact'>Contact</NavLink></li> */}
      </ul>

      <div className='hamburger-menu'>
        <a href='#' onClick={() => setShowMediaIcons(!showMediaIcons)}>
          {showMediaIcons ? (<GrClose className='cls-color' />) : (<GiHamburgerMenu />)}
        </a>
      </div>
    </div>
  );
}