/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './Footer.css'
import { IoLogoLinkedin } from "react-icons/io";
import { NavLink } from 'react-router-dom';
export const Footer = () => {
    return (
        <div className='footer-content'>
            <ul className='copy-menu'>
                <a href='mailto:support@1waydoor.com' target="_blank" rel="noreferrer"> support@1waydoor.com </a> |
                <a href="https://www.linkedin.com/company/1waydoor" target='_blank' rel="noreferrer"><IoLogoLinkedin /></a> |
                <li>Copyright © 2024 1Waydoor LLC </li>
                <li><NavLink to='/privacy'>Privacy</NavLink></li>
                <li><NavLink to='/terms'>Terms</NavLink></li>
            </ul>
        </div>
    )
}
