import React from 'react';
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import CarouselPage from '../Components/Slider/aboutCarousel';
import './about.css';
// import { useEffect, useState } from 'react';
// import GenericCardPopup from '../Components/AboutPopup/AboutPopup';
// import AboutSlider from '../Components/AboutSlider/AboutSlider';

// function handleClick() {
//   let ele = document.getElementsByClassName('name1');
//         ele.innerHTML += 'XYZ';
// }
// const cardData = [
//   { title: 'Card 1', content: 'Popup 1 content', description: 'description' },
//   { title: 'Card 2', content: 'Popup 2 content', description: 'description' },
//   { title: 'Card 3', content: 'Popup 3 content', description: 'description' },
//   { title: 'Card 4', content: 'Popup 4 content', description: 'description' },
// ];
const About = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   // Attach the event listener
  //   window.addEventListener('resize', handleResize);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  return (
    <div>
      <Navbar />
      <div className='client-header' >
        <CarouselPage />
      </div>
    {/* {
      isMobile ? <AboutSlider/> : <GenericCardPopup numberOfCards={4} cardData={cardData}/>
    } */}
    {/* <GenericCardPopup numberOfCards={4} cardData={cardData}/> */}
      <ul className='teamList'>
        <li className='listItem'>
          <div>
            <h2>Design</h2>
            <h5>Create exceptional experiences</h5>
            <div>By adopting a product mindset, we design digital products that adhere to your strategic roadmap. We use design thinking tools to ensure your products enhance the value they provide the people who use them.</div>
          </div>
        </li>
        <li className='listItem'>
          <div>
            <h2>Strategy</h2>
            <h5>Define critical outcomes</h5>
            <div>A winning strategy maximizes your business value and focuses on user outcomes. We analyze your objectives and every aspect of your organization to create a strategic roadmap of your digital transformation journey.</div>
          </div>
        </li>
        <li className='listItem'>
          <div>
            <h2>Engineering</h2>
            <h5>Build durable solutions</h5>
            <div>We build durable, modern software that delivers on your digital transformation objectives. Our technology skills span across the platforms and methodologies you use and are scalable, flexible, and secure.</div>
          </div>
        </li>
      </ul>
      <div className='trustHeader'>Trusted Industry Expertise</div>
      <div className='trustText'>
          For over 16 years, founders have partnered with companies in several highly 
              regulated and specialized industries to solve their most complex business problems. 
              Clients trust us to guide their vision, design innovative products, and deliver secure 
              solutions while understanding the specific nuances of their industry.
          <br />
      </div>
      <ul className='teamList'>
        <li className='listItem'>
            <div>
              <h2>Cloud Migration</h2>
              <h5>Invest In The Cloud For Short and Long-Term Savings</h5>
              <p>Migrating software and systems to the cloud improves scalability, security, and 
                savings. Our cloud experts carefully analyze your business case and existing architecture 
                to plan out a migration roadmap to ensure a smooth transition. We build infrastructure 
                that meets standards and regulations like GDPR, SOC, ISO, NIST, PCI, HIPAA, and FedRAMP. 
                Our team assists clients at a business level as well as technical — benefits realization, 
                business risk management, personnel training, building business cases.</p>
              Application Discovery Services<br />
              OS & Database Migration Services<br />
              AWS Cloud Adoption Framework<br />
              License Management<br />
              Identity & Access Management<br />
            </div>
          </li>
          <li className='listItem'>
            <div> 
              <h2>Cloud-Native</h2>
              <h5>Transform and Disrupt With Cloud-Native</h5>
              <p>Our AWS certified cloud application development experts take full leverage 
                of the cloud-native services and approaches to design for flexibility and scalability 
                without overengineering. We build applications in event-driven, highly-distributed, and 
                serverless approaches. We place a strong emphasis on security, resiliency, performance, 
                cost efficiency, and regulatory compliance when designing cloud-native architectures. 
                Infrastructure as code, Platform-as-a-Service, Serverless processing — we’ll help you 
                effectively leverage cloud-native solutions.
                </p>
              Amazon Web Services<br />Serverless Approach<br />Infrastructure as Code<br />DevOps Services<br />Big Data & Analytics<br />
            </div>
          </li>
          <li className='listItem'>
            <div>
              <h2>DevOps</h2>
              <h5>Combine and Conquer</h5>
              <p>Our DevOps practice that automates software development processes in order to build, 
                test, release, and manage workloads faster and more reliably. Rather than the application 
                development and infrastructure operations teams working in consecutive silos, they work 
                together towards a shared goal. The result is a team that is much more communicative and 
                collaborative. Time to market for software delivery is much faster, meaning that we help 
              you deliver value to your customers much faster.
              </p>
              Automation<br />CI/CD Pipelines<br />Environment Management<br />Configuration Management<br />
            </div>
          </li>
        </ul>
      <Footer />
    </div>
  );
}

export default About;