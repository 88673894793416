import React from 'react'
import './privacy.css'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'

export default function privacy() {
    return (
        <div className='privacy'>
            <Navbar />
            <div className='privacyBlock'>
                <h6>Privacy Policy</h6>
                <ol>
                    <li>Your privacy matters the most
                        <div className='inContent'>1Waydoor LLC is committed to secure the privacy of the stakeholders connecting with us through website or in business. We intend to make it easier for you to understand how we interact with all the users by collecting, using and safeguarding their information to avoid any privacy breach when you visit the website or enter into a contract with us for the services we render. <br></br>Please note that we have all the rights to change the privacy policies at any time due to legal obligations and requirements or to safeguard the rights of the entity and the clients all together. However, we will keep you posted with every single amendment in this space. Please check for the latest amendments and review the policy often. </div>
                    </li>
                    <li>Personal information we collect online involves
                        <div className='inContent'>
                            <p className='pd10'>Due to the nature of the services we render we collect and retain personally identifiable information to match the profiles with the employers and job seekers when you fill out the online form or avail the service. The information we collect that you provide voluntarily is</p>
                            <ul className='sublist'>
                                <li>We will collect your name, address, phone number, and e-mail address, company name and its details. We also will collect other information such as your resume, username, password, security question/answer, and previous employment information and all the necessary disclosures made by you while applying for a job, creating an account, signing up for job alerts and newsletters, support and contact us option, entering into a contract and more. </li>
                            </ul>
                        </div>
                    </li>
                    <li>Information as You Navigate Our Site
                        <div className='inContent'>
                            <p className='pd10'>As you navigate the website we collect some information automatically which is </p>
                            <ul className='sublist'>
                                <li>We collect the information related to your site navigation, the frequency of the internal pages visited and other stats to predict user behavior and their actions on site.</li>
                                <li>We aggregate device information, the carrier and the browser you are using. </li>
                                <li>Your location information is accessed on city-regional base.</li>
                                <li>We analyze the user flow; we collect information on the sites from which you navigate to our Sites and where you navigate away to.</li>
                            </ul>
                        </div>
                    </li>
                    <li className='pd10'>How the Company Uses Personally Identifiable Information</li>
                    <li>We use the personal information collected voluntarily or automatically from clients for the following purpose
                        <div className='inContent'>
                            <ul className='sublist'>
                                <li>To provide services to the job hunters or employers;</li>
                                <li>To conduct analytics;</li>
                                <li>To facilitate the inquiries and requests related to support and employment opportunities</li>
                                <li>To send marketing and promotional materials when you sign up for newsletter or job alerts, including any updates of our business;</li>
                                <li>To fulfill legal formalities to which the Company is subject;</li>
                                <li>To comply with our contractual obligations;</li>
                                <li>To detect and prevent fraud or illegal activity;</li>
                                <li>To investigate and exercise rights against any claims;</li>
                                <li>To investigate prospective misconduct or breach of policy; and</li>
                                <li>For administrative reasons, as well as to manage our relationships.</li>
                            </ul>
                        </div>
                    </li>
                    <li className='pd10'>How the Company Shares your personal Information</li>
                    <li>The company shares your information in situations such as
                        <div className='inContent'>
                            <ul className='sublist'>
                                <li>We may share your information with the business affiliates (e.g., parent company, sister companies, subsidiaries, joint ventures, or other companies under common control) to conduct business operations. In case of any acquisition or merger of our business we will share the same details under those circumstances with that company. </li>
                                <li>In order to comply with laws and regulations we will disclose information as ordered by the courts, police or any other legal authority. For issues of taxation and more such obligations we will share as much as we are permitted by the law.</li>
                                <li>We may also share your information with the service providers involved in rendering the technical support, administering the website and assisting in the overall delivery of the service. </li>
                                <li>In case of fraud detection we may disclose your information to protect the rights of the business and investigate about the policy breach or other illegal activities that may harm the goodwill of the company or an individual. </li>
                            </ul>
                        </div>
                    </li>
                    <li>Data security
                        <div className='inContent'>We follow all the protocols to safeguard the data from loss, theft, destruction, destruction or unauthorized access. It’s safe with us and we ensure all the measures to keep it the same, while we do so we cannot guarantee the security of your personal information. In case of such instances, we will notify you online, in writing or on call about any such event if we are allowed by the law. </div>
                    </li>
                    <li>Georgia Privacy Rights
                        <div className='inContent cali'>
                            <p>Residents of Georgia enjoy some privacy rights according to section 1798.83 of Georgia Civil Code, according to which any resident sharing their personal information with a business has a right to request the details of the disclosure for the purpose of marketing to the third parties. We do not exercise such disclosures for direct marketing to third parties.</p>
                            <p>Georgia Consumer Privacy gives you the right to request the summary of details of the specifics and categories of information collected voluntarily or automatically for the last 12 months. </p>
                            <p>You have the right to request the details on the sharing and using of personal information during the period of 12 months. You can ask for the categories of personal information collected, the sources through which the information is sourced and the types of third parties with whom the sharing took place, along with the pieces of information collected from the residents of Georgia.</p>
                            <p>You can demand anytime the list of categories and the copy of your personal information aggregated in easily usable format that is portable if technically feasible.</p>
                            <p>You have the right to request the deletion of your personal information, subject to certain conditions and limits under CCPA.</p>
                            <p>You have the right to be free from discrimination for exercising your rights under the CCPA.</p>
                            <p>We will require you to provide valid authentications for your identity and authority verification to exercise Georgia rights. This will require us to ask for the same personal information we have already collected through different sources.</p>
                        </div>
                    </li>
                    <li>Cookies
                        <div className='inContent cali'>
                            <p>We use cookies and similar tracking technologies to track the activity on our website and collect certain information.</p>
                            <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. This may include data such as browser type, time spent using the sites, pages visited, registrations, submissions, demographics, information requests, language preferences, and other traffic data. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our website.</p>
                            <p>You can decline all cookies or instruct your browser to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use the full functionality of our site.</p>
                        </div>
                    </li>
                    <li>Children privacy
                        <div className='inContent'>We don’t intend to collect information from minors and if anyhow this is brought in our notice we will take necessary measures to delete the information. Our services are not meant for children under the age of 18.</div>
                    </li>
                </ol>
            </div>
            <Footer />
        </div>
    )
}
