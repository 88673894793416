// src/components/FormComponent.js
import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './SubmitForm.css';
// Adjust the path accordingly

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    howDidYouHear: '',
    incorporated: '',
  });
  const [formDataSubmited, setFomDataSubmited] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Save data to Firestore
      const collectionRef = await addDoc(collection(db, 'users'), formData);
      // Now you can perform operations on the collectionRef
      // For example, add a document
      console.log(collectionRef)
      setFomDataSubmited(true)
    } catch (error) {
      console.error('Error adding data:', error);
      // Handle error scenario
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      {
        formDataSubmited ? <p className='form-group-wrap'>Thank you for your submission!</p> :
          <div>
            <form onSubmit={handleSubmit} className='form'>
              <div className='form-group-wrap'>
                <h5>Please fill out below form to schedule an appointment or receive more information. We look forward to speaking with you.</h5><br />
                <div className='form-group'>
                  <div className='label'>
                    <p>Name:</p>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                  </div>

                  <div className='label'>
                    <p>Company:</p>
                    <input type="text" name="company" maxLength="24" value={formData.company} onChange={handleChange} />
                  </div>

                  <div className='label'>
                    <p>Email:</p>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                  </div>

                  <div className='label'>
                    <p>Phone:</p>
                    <input type="number" name="phone" minLength="10" value={formData.phone} onChange={handleChange} />
                  </div>

                  <div className='label'>
                    <p>How did you hear about us?:</p>
                    <input type="text" name="howDidYouHear" maxLength="20" value={formData.howDidYouHear} onChange={handleChange} />
                  </div>

                  <div className='label'>
                    <p>Have you incorporated your company?:</p>
                    <input type="text" name="incorporated" maxLength="20" value={formData.incorporated} onChange={handleChange} />
                  </div>
                </div>
                <div className='text-area-group'>
                  <div className='text-area'>
                    <p> I'd like to...</p>
                    <textarea id='text-area' type="text-area" name="info" value={formData.info} onChange={handleChange} />
                  </div>
                  <br />
                  <button onClick={handleSubmit}>Submit</button>
                </div>

              </div>

            </form>
          </div>
      }

    </>
  );
};

export default FormComponent;
